<template>
  <section>
    <b-alert
      v-if="Object.keys(subscription).length > 0"
      variant="primary"
      show
    >
      <h4
        class="alert-heading text-center"
        style="font-size: 16px"
      >
        Você está com sua assinatura ativa! Você tem mais
        {{ subscription.days_left }} dia{{
          subscription.days_left > 1 ? "s" : ""
        }}
        de assinatura restante, vencendo em {{ subscription.due_date }}.
      </h4>
    </b-alert>
    <div
      v-if="Object.keys(subscription).length > 0"
      class="d-flex justify-content-center mb-2"
    >
      <b-button
        variant="relief-warning"
        @click.stop="download()"
      >
        Download
      </b-button>
    </div>
    <b-card>
      <b-card-title class="d-flex justify-content-center">
        BOT Verificador Infinity
      </b-card-title>

      <b-card-text>
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-center"
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/m3BIuS5qvuo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
            />
          </b-col>

          <b-col
            cols="12"
            class="mt-2"
          >
            <b-alert
              variant="info"
              show
            >
              <h4
                class="alert-heading text-center"
                style="font-size: 16px"
              >
                Colete de uma forma fácil e rápida os Links das Fotos de seus
                perfis!
              </h4>
            </b-alert>
          </b-col>

          <b-col cols="12">
            <p class="text-center">
              Está cansado de verificar centenas de perfis para saber se alguns
              foram desativados? Está montando algumas contas e quer saber se
              todos estão corretas para adicionar nas plataformas? Acabou de
              comprar uma quantidade grande de perfis e quer verificar se estão
              todos funcionando? Você que monta e vende muitas contas e quer
              entregar para o seu cliente apenas as contas ativas? Esse nosso
              bot foi feito para você!
            </p>
          </b-col>

          <b-col
            cols="12"
            class="mt-2"
          >
            <b-alert
              variant="info"
              show
            >
              <h4
                class="alert-heading text-center"
                style="font-size: 16px"
              >
                O BOT Verificador Infinity vem com o intuito de facilitar sua
                vida ao verificar suas contas do Instagram ou Tiktok! O nosso
                bot loga apenas na primeira conta e verifica o resto, assim
                tendo uma maior segurança evitando quedas massivas de seus
                perfis.
              </h4>
            </b-alert>
          </b-col>

          <b-col cols="12">
            <p class="text-center">
              Configure todas as informações que desejar verificar em um perfil
              do Instagram! Verifique se suas
            </p>
          </b-col>

          <b-col cols="12">
            <b-alert
              variant="info"
              show
            >
              <h4
                class="alert-heading text-center"
                style="font-size: 16px"
              >
                Verifique se suas contas estão Ativas ou Desativadas, se possuem
                alguma quantidade de seguidores ou seguindo, quantidade de
                fotos, destaques, se possui bio ou não e muito mais!
              </h4>
            </b-alert>
          </b-col>

          <b-col cols="12">
            <p class="text-center">
              Ao final de cada verificação o nosso ‘bot’ separa os perfis que
              não se encaixam com seus requisitos configurados inicialmente. Ele
              separa em 3 arquivos: Perfis Bloqueados, Perfis Desqualificados e
              Perfis Qualificados, dessa forma facilitando sua vida para já
              separar em sua planilha os perfis que deseja.
            </p>
          </b-col>

          <b-col cols="12">
            <p class="text-center">
              Você que trabalha com muitas contas por dia e quer ter um controle
              maior sobre suas contas; se você cria e monta contas com
              frequência; vende e revende contas... O ‘bot’ foi feito pensando
              em você para facilitar seu processo de verificação, dessa forma
              ganhando muito mais tempo para fazer outras coisas enquanto o BOT
              verifica as contas para você! Não deixe de adquirir nosso BOT!
            </p>
          </b-col>

          <b-col cols="12">
            <b-alert
              variant="info"
              show
            >
              <h4
                class="alert-heading text-center"
                style="font-size: 16px"
              >
                Verfique os Perfis do Instagram e Tiktok + Colete os Links das
                Fotos
              </h4>
            </b-alert>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <b-row class="pricing-card">
      <b-col
        v-for="(item, index) in plans"
        :key="index"
        md="4"
      >
        <b-card align="center">
          <div
            v-show="true"
            class="pricing-badge text-center"
          >
            <b-badge
              variant="light-info"
              pill
              class="font-small-4"
            >
              <span class="py-1 px-1">
                {{ item.plan }}
              </span>
            </b-badge>
          </div>

          <div class="annual-plan">
            <div class="plan-price mt-2">
              <sup class="font-medium-1 font-weight-bold text-primary">R$ </sup>
              <span
                class="pricing-basic-value font-weight-bolder text-primary"
              >{{ item.price }}</span>
            </div>
          </div>

          <b-button
            block
            variant="relief-primary"
            class="mt-1"
            :disabled="loading"
            @click.stop="purchase(item)"
          >
            <span v-if="!loading">Comprar</span>
            <b-spinner
              v-else
              small
            />
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BAlert,
  BBadge,
  BButton,
  BSpinner
} from 'bootstrap-vue'
import SwalMessages from '../BuyServices/utils/SwalMessages'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BButton,
    BSpinner
  },
  data: () => ({
    loading: false,
    plans: [],
    subscription: []
  }),
  async mounted () {
    await this.getPlans()
    await this.getSubscription()
  },
  methods: {
    async getPlans () {
      const url = '/user/verificador_infinity/get_plans'

      await this.$http.get(url).then(res => {
        this.plans = res.data
      })
    },
    async getSubscription () {
      const url = '/user/verificador_infinity/subscription'

      await this.$http.get(url).then(res => {
        this.subscription = res.data
      })
    },
    async purchase (item) {
      this.loading = true
      const url = `/user/verificador_infinity/purchase/${item.id}`

      await this.$http
        .get(url)
        .then(res => {
          if (res.data.message === 'successfully created.' && res.status === 201) this.success()
          if (res.data.message === 'no balance.' && res.status === 200) this.noBalance()
        })
    },
    async success () {
      await this.getSubscription().finally(() => {
        this.$swal({
          title: 'Compra realizada com sucesso!',
          icon: 'success',
          text: `Você está com sua assinatura ativa! Você tem mais
        ${this.subscription.days_left} dia${
  this.subscription.days_left > 1 ? 's' : ''
}
        de assinatura restante, vencendo em ${this.subscription.due_date}.`,
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })

        this.loading = false
      })
    },
    noBalance () {
      this.loading = false

      this.$swal(SwalMessages.no_balance).then(buttons => (buttons.isConfirmed
        ? this.$router.push({ name: 'Adicionar Saldo' })
        : null))
    },
    download () {
      window.open('https://drive.google.com/drive/folders/1zyI1iFQclYYYzZN0a-L8tzACUTQRRObF')
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
